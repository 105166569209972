// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-blog-id-js": () => import("./../../../src/pages/blogs/{Blog.id}.js" /* webpackChunkName: "component---src-pages-blogs-blog-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-volunteer-capture-js": () => import("./../../../src/pages/new-volunteer-capture.js" /* webpackChunkName: "component---src-pages-new-volunteer-capture-js" */),
  "component---src-pages-our-activities-js": () => import("./../../../src/pages/our-activities.js" /* webpackChunkName: "component---src-pages-our-activities-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-volunteer-profiles-js": () => import("./../../../src/pages/volunteer-profiles.js" /* webpackChunkName: "component---src-pages-volunteer-profiles-js" */)
}

